<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_packages')"
                        :isNewButton="checkPermission('period_store')"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_packages')"
                              :isNewButton="checkPermission('paymentpackage_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <b-row v-if="paymentPackage">
                <b-col cols="12"><h3>{{this.paymentPackage.name}} - {{this.paymentPackage.scholarship_rate}}</h3></b-col>
                <b-col cols="12" v-if="periods">
                    <b-tabs content-class="py-5 position-relative">
                        <div class="tabs-bottom-line"></div>
                        <b-tab v-for="period in periods" v-bind:key="period" :title="period">
                            <div v-for="type in Object.keys(products[period])">
                                <h5>{{$t(type=='Y' ? 'new_student' : 'current_student')}}</h5>
                                <ol>
                                    <li v-for="product in products[period][type]" v-bind:key="product.id">
                                        <span class="badge badge-info">{{product.product.name}}</span> <b>{{$t('price')}}:</b> {{product.price}} <b>{{$t('cash_price')}}:</b> {{product.cash_price}}
                                        <b-button variant="outline-primary" style="display: inline" class="ml-1" @click="add();form=product;"><i class="ri-edit-2-line"></i></b-button>
                                        <b-button variant="outline-danger" style="display: inline" class="ml-1" @click="deleteProduct(product.id)"><i class="ri-delete-bin-3-line"></i></b-button>
                                        <b-button variant="outline-success" style="display: inline" class="ml-1" @click="addInstallment(product.id)"><i class="ri-add-line"></i></b-button>
                                        <b-card v-if="product.installments.length>0" class="mt-1">
                                            <div v-for="installment in product.installments" class="mt-1">
                                                <span class="badge badge-warning">{{installment.installment_number}}. {{$t('installment')}}</span> <b>{{$t('amount')}}:</b> {{installment.amount}} <b>{{$t('due_date')}}:</b> {{installment.due_date}}
                                                <b-button variant="outline-primary" style="display: inline" class="ml-1" @click="addInstallment(installment.id);installmentForm=installment;"><i class="ri-edit-2-line"></i></b-button>
                                                <b-button variant="outline-danger" style="display: inline" class="ml-1" @click="deleteInstallment(installment.id)"><i class="ri-delete-bin-3-line"></i></b-button>
                                            </div>
                                        </b-card>
                                    </li>
                                </ol>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('period')">
                                                <payment-periods-selectbox v-model="form.period_id">
                                                </payment-periods-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="product_id" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('product')">
                                                <payment-products-selectbox v-model="form.product_id">
                                                </payment-products-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('type')">
                                                <multi-selectbox v-model="form.type" type="number" :options="typeOptions" :multiple="false">
                                                </multi-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="price" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('price')">
                                                <b-form-input v-model="form.price" type="number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="cash_price" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('cash_price')">
                                                <b-form-input v-model="form.cash_price" type="number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('currency')">
                                                <currency-selectbox v-model="form.currency">
                                                </currency-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>

            <CommonModal ref="modalInstallment" size="md">
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="installmentStoreForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="installment_number" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('installment_number')">
                                                <b-form-input v-model="installmentForm.installment_number" type="number" step="1">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('amount')">
                                                <b-form-input v-model="installmentForm.amount" type="number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('due_date')">
                                                <b-form-input v-model="installmentForm.due_date" type="date">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="storeInstallment" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import PaymentPackageProductService from "@/services/PaymentPackageProductService";
import PaymentPackageProductInstallmentService from "@/services/PaymentPackageProductInstallmentService";
import PaymentPackageService from "@/services/PaymentPackageService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox.vue";
import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox.vue";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox.vue";

export default {
    components: {
        CurrencySelectbox,
        PaymentProductsSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t("payment_packages"),
        };
    },
    data() {
        return {
            id: 0,
            form: {},
            installmentForm: {},
            paymentPackage: null,
            products:{},
            periods:null,
            typeOptions: [
                {value:'Y', text: this.$t('new_student')},
                {value:'E', text: this.$t('current_student')},
            ]
        };
    },
    methods: {
        callService(config) {
            return PaymentPackageProductService.getAll(config)
        },
        loadPackage() {
            if (this.id > 0) {
                PaymentPackageService.get(this.id)
                    .then((response) => {
                        this.paymentPackage = response.data.data;
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(this.$t("api." + error.data.message));
                        }
                    });
            }
        },
        loadProducts() {
            if (this.id > 0) {
                const config = {
                    params: {
                        payment_package_id: this.id,
                        limit: -1,
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                };
                PaymentPackageService.getProducts(config)
                             .then((response) => {
                                 this.products = response.data.data;
                                 this.periods=Object.keys(this.products);
                             })
                             .catch((error) => {
                                 if (error.data.message) {
                                     this.$toast.error(this.$t("api." + error.data.message));
                                 }
                             });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                this.form.payment_package_id = this.id;
                PaymentPackageProductService.store(this.form)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.loadProducts();
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        },
        deleteProduct(id) {
            this.deleteModal(() => {
                PaymentPackageProductService.del(id)
                             .then((response) => {
                                 this.$toast.success(this.$t("api." + response.data.message));
                                 this.loadProducts();
                             })
                             .catch((error) => {
                                 this.$toast.error(this.$t("api." + error.data.message));
                             });
            });
        },
        add() {
            this.$refs.storeForm.reset();
            this.form = {id:0};
            this.$refs.modal.$refs.commonModal.show();
        },
        addInstallment(id) {
            this.$refs.installmentStoreForm.reset();
            this.installmentForm = {id:0, payment_package_product_id: id};
            this.$refs.modalInstallment.$refs.commonModal.show();
        },
        async storeInstallment() {
            const isValid = await this.$refs.installmentStoreForm.validate();
            if (isValid) {
                PaymentPackageProductInstallmentService.store(this.installmentForm)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.loadProducts();
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        },
        deleteInstallment(id) {
            this.deleteModal(() => {
                PaymentPackageProductInstallmentService.del(id)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.loadProducts();
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.loadPackage();
        this.loadProducts();
    }
};
</script>

